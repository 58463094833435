﻿.method-list {
    padding: 0 0.6rem;

    ul {
        list-style: none;
    }

    &__load-more {
        margin: 0 auto 70px auto;

        .button {
            border-radius: 100px;
            background: #F0F0F0;
            color: #2e8fff;
            text-transform: uppercase;
            padding: 1.05em 2.7em;

            &:hover, &:focus {
                background: #dbdbdb;
            }
        }
    }
}
