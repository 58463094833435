﻿.terms-social {
    background: get-color(primary);
    padding: 30px 0;

    a {
        color: $white;
    }

    &__terms {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        justify-content: center;
        padding-bottom: 35px;

        li:not(:last-child) {
            padding-right: 20px;
        }

        @include breakpoint(medium) {
            justify-content: flex-start;
            padding-bottom: 0;
        }
    }

    &__socials {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        justify-content: center;

        li:not(:last-child) {
            padding-right: 20px;
        }

        a {
            font-size: 290%;

            &.icon-instagram-circle {
                font-size: 312%;
            }

        }

        @include breakpoint(medium) {
            justify-content: flex-end;
        }
    }
}
