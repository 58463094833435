﻿.btn-close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 20px;
    height: 20px;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
        opacity: 1;

        @include breakpoint(medium) {
            &:before {
                transform: rotate(-45deg);
                transition: transform 0.3s;
            }

            &:after {
                transform: rotate(45deg);
                transition: transform 0.3s;
            }
        }
    }

    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 30px;
        width: 2px;
        background-color: #2D8FFF;

        @include breakpoint(medium) {
            height: 20px;
        }
    }

    &:before {
        transform: rotate(45deg);
        transition: transform 0.3s;
    }

    &:after {
        transform: rotate(-45deg);
        transition: transform 0.3s;
    }
}

.button {
    &--fixed-contact-btn {
        position: fixed;
        bottom: 0;
        right: 30px;
        border-radius: 50px;
        padding: 15px 35px;
        z-index: 2;
    }
}