﻿.coc-form.umbraco-forms {
    background-color: #fff;
    color: #000;
    padding: 0;

    &-submitmessage {
        display: block;
        background: #fff;
        padding: 50px 20px;
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
    }


    .field-validation-error {
        display: block;
        color: #e74c3c;
    }

    .input-validation-error {
        border: 2px solid #e74c3c !important;
        color: #e74c3c;
    }

    &--grey {
        padding: 30px;
        background: #f0f0f0;

        input[type="radio"]:checked + label,
        input[type="radio"] + label,
        input[type="checkbox"]:checked + label,
        input[type="checkbox"] + label {
            &::after {
                background-color: #fff !important;
            }
        }
    }

    &--blue {
        background: #2e8fff;
        color: #fff;

        .field-validation-error {
            color: #FFAA14;
        }

        .input-validation-error,
        input[type="radio"]:not(:checked).input-validation-error + label,
        input[type="radio"]:checked.input-validation-error + label,
        input[type="checkbox"]:not(:checked).input-validation-error + label,
        input[type="checkbox"]:checked.input-validation-error + label,
        .checkboxlist input {
            border: 2px solid #FFAA14 !important;
            color: #FFAA14;
        }

        input[type="submit"] {
            background-color: #fff !important;
            color: #000 !important;

            &:hover {
                background-color: #f0f0f0 !important;
            }
        }

        input[type="file"] {
            & + label {
                background-color: #fff !important;
                color: #000 !important;
            }

            &:focus + label,
            & + label:hover {
                background-color: #f0f0f0 !important;
            }
        }
    }

    &--purpel {
        padding: 30px;
        background: #370037;
        color: #fff;

        input[type="submit"] {
            background-color: #fff !important;
            color: #000 !important;

            &:hover {
                background-color: #f0f0f0 !important;
            }
        }

        input[type="file"] {
            & + label {
                background-color: #fff !important;
                color: #000 !important;
            }

            &:focus + label,
            & + label:hover {
                background-color: #f0f0f0 !important;
            }
        }
    }

    &--yellow {
        padding: 30px;
        background: #efc76b;
        color: #fff;

        input[type="submit"] {
            background-color: #fff !important;
            color: #000 !important;

            &:hover {
                background-color: #f0f0f0 !important;
            }
        }

        input[type="file"] {
            & + label {
                background-color: #fff !important;
                color: #000 !important;
            }

            &:focus + label,
            & + label:hover {
                background-color: #f0f0f0 !important;
            }
        }
    }

    &-label {
        font-weight: bold;
        color: currentColor;
        font-size: 1rem;
    }

    select,
    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='email'],
    [type='number'],
    textarea {
        border: 1px solid #ccc;
        box-shadow: none;
        height: 50px;
        font-size: 1rem;
        padding: 0 20px;
        margin-bottom: 5px;
        border-radius: 3px;

        &:focus {
            border: 2px solid #ccc;
        }
    }

    textarea {
        height: 150px;
        padding: 10px 20px;
    }


    input[type="radio"]:not(:checked), input[type="radio"]:checked,
    input[type="checkbox"]:not(:checked), input[type="checkbox"]:checked,
    .checkboxlist input {
        position: absolute;
        left: -9999px;
        visibility: hidden;
        opacity: 0;

        &.input-validation-error + label {
            border: 2px solid #e74c3c !important;
        }

        + label:hover::before {
            opacity: .3;
        }
    }

    input[type="radio"] + label,
    input[type="checkbox"] + label {
        display: inline-block;
        width: 100%;
        padding: 0;
        padding-left: 35px;
        margin: 0;
        font-size: 1rem;
        margin-bottom: 8px;
        position: relative;
        cursor: pointer;
        z-index: 2;
        overflow: hidden;
        transition: color .2s ease-in-out;
        border-radius: 3px;

        &::before,
        &:after {
            width: 25px;
            height: 25px;
            content: '';
            margin-top: 2px;
            border: none;
            background-color: #f1f3f3;
            background-repeat: no-repeat;
            background-position: 3px 4px;
            z-index: 2;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            transition: all 200ms ease-in;
            border-radius: 3px;
        }

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 25 25' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23ffffff' fill-rule='nonzero'/%3E%3C/svg%3E ");
            opacity: 0;
            z-index: 3;
            background-color: #008fff;
        }
    }

    input[type="radio"]:checked + label,
    input[type="checkbox"]:checked + label {
        &:hover::before,
        &:before {
            opacity: 1;
        }
    }

    input[type="radio"] + label::after,
    input[type="radio"] + label::before {
        border-radius: 50%;
    }

    &-field {
        margin-bottom: 20px;
    }

    input[type="submit"] {
        margin: 0;
        border-radius: 100px;
        text-transform: uppercase;
        padding: 1.2em 1.5em;
        transition: all ease-in-out 350ms;
        display: inline-block;
        vertical-align: middle;
        border: 1px solid transparent;
        font-family: inherit;
        font-size: 0.9rem;
        line-height: 1;
        text-align: center;
        cursor: pointer;
        background-color: #2e8fff;
        color: #fff;

        &:hover {
            background-color: #0478ff;
        }
    }

    input[type="file"] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        & + label {
            margin: 10px 0 0;
            border-radius: 100px;
            text-transform: uppercase;
            padding: 1.2em 1.5em;
            transition: all ease-in-out 350ms;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid transparent;
            font-family: inherit;
            font-size: 0.9rem;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            cursor: pointer;
            background-color: #2e8fff;
            color: #fff;
        }

        &:focus + label,
        & + label:hover {
            background-color: #0478ff;
        }
    }
}


.pika-single .pika-table {
    tr,
    thead,
    tbody {
        border: none;
        background: transparent !important;
    }


    th {
        color: #000;
        font-size: 14px;
    }

    .pika-button {
        background: transparent;
        font-size: 14px;
        text-align: center;
    }

    .pika-button:hover {
        color: #fff;
        background: #ff8000;
        box-shadow: none;
        border-radius: 3px;
    }

    .is-selected .pika-button {
        color: #fff;
        font-weight: bold;
        background: #2e8fff;
        box-shadow: inset 0 1px 3px #178fe5;
        border-radius: 3px;
    }
}