﻿.favourite-btn {
    position: absolute;
    margin: 20px;
    right: 0;
    cursor: pointer;

    &__btn {
        background: #FFFFFF;
        border-radius: 50px;
        padding: 0.65rem;
        cursor: pointer;

        @include breakpoint(large) {
            padding: 0.9rem 0.8rem 0.7rem 0.8rem;
        }



        svg {
            width: 23px;
            height: 23px;

            @include breakpoint(large) {
                width: 30px;
                height: 30px;
            }
        }
    }

    .heart-icon-filled {
        display: none;
    }

    .heart-icon-outline {
        display: block;
    }

    &--fav {
        .heart-icon-outline {
            display: none;
        }

        .heart-icon-filled {
            display: block;
        }
    }

    &--fav {
        .favourite-btn__label {
            &--not-fav {
                display: none;
            }

            &--fav {
                display: block;
            }
        }
    }

    &__label {
        font-size: 18px;
        margin-left: 1rem;

        &--not-fav {
            display: block;
        }

        &--fav {
            display: none;
        }
    }
}
