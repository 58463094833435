﻿.method-page {
    padding: 45px 1rem;

    @include breakpoint(large) {
        padding: 80px 0;
    }

    p {
        margin-bottom: 1.3rem;
    }

    &__heading {
        h1 {
            margin-bottom: 0.4rem;
        }

        @include breakpoint(large) {
            h1 {
                margin-bottom: 1.6rem;
            }
        }
    }

    &__body {

        &__readmore {
            height: 400px;
            overflow: hidden;
            -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));

            @include breakpoint(large) {
                height: unset;
                overflow: unset;
                -webkit-mask-image: none;
            }
        }

        &__readmore-button {
            margin: 0;
            background: #f0f0f0;
            color: #2e8fff;
            font-size: 14px;
            border-radius: 100px;
            padding: 0.64rem 1.3rem;
            text-transform: uppercase;
            -webkit-transition: all ease-in-out 350ms;
            transition: all ease-in-out 350ms;

            @include breakpoint(large) {
                display: none;
            }
        }
    }

    &__material-content {
        margin: 33px 0;
        padding: 30px 0 15px 0;
        border-top: 1px solid #D8D8D8;
        border-bottom: 1px solid #D8D8D8;
        display: flex;
        flex-direction: column;

        @include breakpoint(large) {
            padding: 30px 0;
            flex-direction: row;
        }

        &__cta {
            flex-basis: 50%;
            margin-bottom: 30px;

            @include breakpoint(large) {
                margin-bottom: unset;
            }

            a {
                margin: 0;
                font-size: 14px;
                border-radius: 100px;
                padding: 0.64rem 1.3rem;
                text-transform: uppercase;
                -webkit-transition: all ease-in-out 350ms;
                transition: all ease-in-out 350ms;


                @include breakpoint(large) {
                    font-size: 16px;
                    padding: 0.94rem 2.4rem;
                }
            }
        }



        &__additional {
            flex-basis: 50%;

            h6 {
                font-size: 18px;
            }

            ul {
                margin-left: 5px;
                margin-bottom: 0px;

                &.dashed {
                    list-style-type: none;

                    & > li {
                        text-indent: -5px;

                        &:before {
                            content: "-";
                            text-indent: -5px;
                        }
                    }
                }
            }
        }
    }



    .method-page__content, &__content {
        padding-right: 0;

        @include breakpoint(large) {
            padding-right: 2rem;
        }

        @include breakpoint(xxlarge) {
            padding-right: 2.28rem;
        }
    }

    &__sidebar {
        order: 2;

        @include breakpoint(large) {
            order: unset;
        }

        &__content {
            background: #2e8fff;
            color: #fff;

            img {
                width: 100%;
                height: auto;
                margin-bottom: 0.7rem;
            }

            h4 {
                padding: 15px 30px 0px 30px;
            }

            p {
                padding: 0 35px 35px 35px;
            }
        }
    }

    &__favourite-btn {
        .favourite-btn {
            padding-right: 0.55556rem;
            padding-left: 0.55556rem;
            order: 1;
            margin: 0 0 30px 0;
            position: unset;
            display: flex;
            flex-direction: row;
            align-items: center;

            @include breakpoint(large) {
                order: unset;
                margin: 0 0 50px 0;
            }

            &__btn {
                background: #F0F0F0;
                padding: 10px;
                cursor: pointer;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &__tabs-container {
        margin-bottom: 40px;
        padding-right: 0.55556rem;
        padding-left: 0.55556rem;
        order: 3;

        @include breakpoint(large) {
            background: #F0F0F0;
            margin-bottom: 80px;
            padding-top: 60px;
            padding-bottom: 60px;
            padding-right: 50px;
            padding-left: 50px;
            order: unset;
        }

        @include breakpoint(xlarge) {
            padding-right: 115px;
            padding-left: 115px;
        }
    }

    &__tab-titles {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 25px 0;

        @include breakpoint(medium) {
            overflow-x: visible;
            overflow-y: visible;
            padding: unset;
            margin-top: 0px;
            margin-top: 30px;
        }

        @include breakpoint(large) {
            margin-top: 0px;
        }

        .tab-title {
            color: black;
            font-weight: bold;
            white-space: nowrap;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            transition: color 0.3s;

            &:hover {
                color: #2D8FFF;
            }

            @include breakpoint(large) {
                -webkit-box-flex: unset;
                -ms-flex: unset;
                flex: unset;
            }

            &.active-tab, :hover {
                color: #2D8FFF;
            }
        }

        a {
            border-right: 2px solid #D8D8D8;
            font-size: 16px;
            padding: 0 1rem 0 1rem;

            @include breakpoint(large) {
                padding: 0 1.5rem 0 1.5rem;
            }

            &:first-child {
                padding-left: 0;
                padding-right: 1rem;

                @include breakpoint(large) {
                    padding-right: 1.5rem;
                }
            }

            &:last-child {
                border-right: unset;
            }
        }
    }

    &__tab-content {
        margin-top: 20px;
        max-width: 785px;

        h1, h2, h3, h4, h5 {
            margin-bottom: 1rem;
        }

        @include breakpoint(medium) {
            margin-top: 60px;
        }

        .tab-content-text {
            display: none;
        }

        .active-content {
            display: block;
        }

        ul {

            li {
                margin-bottom: 35px;

                &::marker {
                    color: red;
                }
            }
        }

        ul li::before {
            content: "\2022";
            color: #ababab;
            font-weight: bold;
            display: inline-block;
            width: 0.7em;
            margin-left: -1em;
            font-size: 29px;
        }
    }



    &__related-methods {
        width: 100%;
        padding-right: 0.55556rem;
        padding-left: 0.55556rem;
        order: 4;

        h3 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        @include breakpoint(large) {
            padding-right: unset;
            padding-left: unset;
            order: unset;

            h3 {
                font-size: 40px;
                margin-bottom: 60px;
            }
        }

        ul {
            list-style: none;
            display: flex;
            overflow-y: hidden;
            margin-left: 0;

            @include breakpoint(xlarge) {
                overflow-y: unset;
            }

            li {
                flex: 0 0 auto;
                width: 98%;
                overflow: hidden;
                padding: 0 0.6rem 0 0;

                @include breakpoint(medium) {
                    width: 49%;
                }

                @include breakpoint(xlarge) {
                    width: 33.33333%;
                    overflow: unset;
                    padding: 0 0.6rem;
                }
            }
        }
        /* width */
        ::-webkit-scrollbar {
            height: 5px;
        }
        /* Track */
        ::-webkit-scrollbar-track {
            background: #F0F0F0;
        }
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #2D8FFF;
        }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #0177ff;
        }
    }
}
