﻿.image-text-grid-section {
    padding: 20px;
    margin: 0;

    @include breakpoint(medium) {
        padding: 0;
    }

    .cell {
        height:auto;
    }

    &__item {
        margin-bottom: 15px;
        height: auto;

        @include breakpoint(medium) {
            margin-bottom: 45px;
        }
    }

    &__container {
        padding: 20px 0;
    }

    &__media {
        img {
            width: 100%;
        }
    }

    &__cta {
        text-align: left;
    }

    &__cta .button {
        font-size: 0.75rem;
        border-radius: 30px;
        text-transform: uppercase;
        margin: 0;
        border-radius: 100px;
        text-transform: uppercase;
        padding: 1.1em 1.5em 1em;
    }
}
