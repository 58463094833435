﻿.event-page {
    padding: 20px 1rem;

    @include breakpoint(large) {
        padding: 70px 1rem;
    }

    &__date-time {
        color: get-color(primary);
        font-weight: bold;
        margin-bottom: 0;

        &:first-letter {
            text-transform: capitalize;
        }
    }

    &__meta {
        list-style: none;
        padding: 0;
        margin: 30px 0 0 10px;
    }

    &__meta-item {
        display: flex;
        align-items: baseline;

        &:before {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: get-color(primary);
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &__meta-item__content {
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: 25px;

        > * {
            display: inline;
        }
    }

    &__form {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #f0f0f0;

        > * {
            @include breakpoint(xlarge) {
                width: 50%;
            }
        }
    }

    &__sidebar {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #f0f0f0;

        @include breakpoint(xlarge) {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__article {
        img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            display: block;
        }
    }
}
