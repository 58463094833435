﻿.event-list-item {

    @media (min-width: 500px) {
        display: flex;
        align-items: flex-start;
    }

    &__cal {
        margin-bottom: 20px;

        @media (min-width: 500px) {
            width: 160px;
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: 0;
        }
    }

    &__content {
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: 40px;
    }

    &__title {
        font-size: 30px;
        margin-bottom: 0;
    }

    &__location {
        font-size: 20px;
        font-weight: bold;
        color: #1a1a1a;
    }

    &__description {
        font-size: 18px;
        font-weight: normal;
        color: #4a4a4a;
    }
}
