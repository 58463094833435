@charset 'utf-8';

@import 'furniture/fonts.scss';

@import 'settings';
@import 'foundation';
// @import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
// @include foundation-button-group;
// @include foundation-close-button;
// @include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
@include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
@include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
@include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
// @include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
// @include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
// @include motion-ui-transitions;
// @include motion-ui-animations;

// Furniture Styles
body, html {
    overflow-x: hidden;
    height: 100%;
}

header, main, footer {
    .grid-container {
        padding: 0;

        @include breakpoint(medium) {
            padding: 0 1.5rem;
        }

        @include breakpoint(xlarge) {
            padding: 0 56px;
        }

        @include breakpoint(xxlarge) {
            padding: 0;
        }

    }
}

section {
    margin-bottom: 0px;

    @include breakpoint(xlarge) {
        margin-bottom: 50px;
    }
}

// Baseline Furniture
@import 'furniture/header.scss';
@import 'furniture/footer.scss';
@import 'furniture/menu-overwrite.scss';
@import 'footer/_google-maps.scss';
@import 'footer/_terms-social.scss';
@import 'footer/_menu-contact.scss';

@import "furniture/favourites-menu";
@import "furniture/button.scss";

// Elements
@import 'elements/_event-time.scss';
@import 'elements/_event-list-item.scss';
@import 'elements/_umbraco-forms.scss';
@import 'elements/_coc-forms.scss';
@import 'elements/_member-type-item.scss';
@import 'elements/_method-list-item.scss';
@import 'elements/_method-list.scss';
@import 'elements/_favourite-btn.scss';


// Baseline Sections
@import 'sections/_feature-section.scss';
@import 'sections/_image-grid-section.scss';
@import 'sections/_image-text-grid-section.scss';

@import 'sections/_hero-cirkel-section.scss';
@import 'sections/_hero-section.scss';
@import 'sections/_heading-section.scss';
@import 'sections/_contact-section.scss';
@import 'sections/_long-content-section.scss';
@import 'sections/_events-overview-section.scss';
@import 'sections/_member-type-overview-section.scss';

@import 'pages/_event-page.scss';
@import 'pages/_method-page.scss';
@import 'pages/_method-overview-page.scss';

// Baseline Macros
@import 'macros/_contact-macro.scss';
@import 'macros/_info-list-macro.scss';


// WHY?
* {
    outline: none!important;
}

// NEED TO MOVE TO OWN SASS FILE
.instagram_gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;

    @include breakpoint(medium) {
        padding:0;
    }

    a {
        width: 100%;
        opacity: 1;
        transition: all .35s ease-in-out;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        @include breakpoint(medium) {
            width: 50%;
        }

        @include breakpoint(xlarge) {
            width: 25%;
        }
    }

    img {
        width: 100% !important;
        margin: 0 !important;
        opacity: 1;
        cursor: pointer;
        transition: all .35s ease-in-out;

        &:hover {
            opacity: .8;
            transition: all .35s ease-in-out;
        }
    }
}

// QUICK FIX - NEED TO MOVE TO FORMS SASS
.umbraco-forms-form .checkboxlist label {
    clear: none !important;
}

@import 'elements/_favourite-list-print.scss';