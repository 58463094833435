﻿.header {
    position: relative; 

    @include breakpoint(xlarge down) {
        .grid-container {
            padding: 20px 20px 35px;

            body:not(.frontpage) & {
                padding: 30px 15px;
            }

            > .grid-x {
                justify-content: space-between;
            }
        }
    }

    body:not(.frontpage):not(.hubpage) & {
        background: get-color(primary);
    }

    &__logo-container {
        display: flex;

        @include breakpoint(medium) {
            display: block;
        }

        @include breakpoint(medium down) {
            width: auto !important;
        }
    }

    &__logo {

        @include breakpoint(medium) {
            padding: 30px 0;
        }

        @include breakpoint(xlarge) {
            padding: 70px 0;
        }
    }

    &__mobile-menu {
        position: relative;
        flex-grow: 1;
        width: 65px;
        height: 65px;
        background: $primary-color;
        text-align: center;
        position: absolute;
        bottom: 100%;
        margin: 1rem;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.12);
        transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);

        @include breakpoint(xlarge) {
            display: none;
        }

        span {
            display: block;
            width: 35px;
            height: 3px;
            background: $white;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translateX(50%);
            transition: all .35s ease-in-out;

            .-active & {
                background: transparent;
                transition: all .35s ease-in-out;
            }

            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                width: 35px;
                height: 3px;
                background: $white;
                left: 0;
                transition: all .35s ease-in-out;
                transform-origin: 50%;
            }

            &:before {
                top: -12px;

                .-active & {
                    top: 0;
                    transform: rotate(45deg);
                    transition: all .35s ease-in-out;
                }
            }

            &:after {
                bottom: -12px;

                .-active & {
                    bottom: 0;
                    transform: rotate(-45deg);
                    transition: all .35s ease-in-out;
                }
            }
        }
    }

    &__menus {
        position: absolute;
        z-index: 100;
        display: flex;
        flex-direction: column;
        position: static;
        background: transparent;
        bottom: auto;
        z-index: auto;
        transform: none;

        @include breakpoint(xlarge down) {
            width: auto !important;
            justify-content: center;
        }

        body:not(.frontpage):not(.hubpage) & a {
            color: $white;

            &:hover {
                color: rgba($white, 0.65);
            }
        }

        a {
            font-weight: bold;
            padding: 0;
            text-align: center;
            transition: all .35s ease-in-out;

            &:hover {
                color: rgba($white, 0.65);
            }

            @include breakpoint(medium) {
                padding: 0.7rem 1rem;
                text-align: right;
            }
        }
    }

    &__menu {
        display: flex;
        justify-content: center;
        order: 2;
        align-items: center;
        flex-grow: 1;
        display: none;

        @include breakpoint(xlarge) {
            justify-content: flex-end;
            padding-bottom: 70px;
            display: flex;

            li.is-dropdown-submenu-parent:last-child > a {
                padding-right: 0;
            }
        }

        a {
            font-size: 18px;

            @include breakpoint(medium down) {
                padding: 1rem 2rem;
            }
        }

        li + li a {
            border-left: 1.5px solid rgba(255, 255, 255, 0.2);
        }
    }



    &__corporate {
        display: flex;
        order: 1;
        width: 100%;
        align-items: center;
        justify-content: center;
        align-items: flex-end;
        justify-content: flex-end;

        @include breakpoint(xlarge) {
            height: 70px;
        }

        .favourite-menu-btn {
            display: flex;
            flex-direction: row;
            position: relative;
            padding-bottom: 6px;
            margin-right: 5px;

            @include breakpoint(medium) {
                margin-right: 50px;
            }

            svg {
                width: 25px;
                height: 23px;
                cursor: pointer;
                color: #fff;

                body.frontpage & {
                    @media only screen and (max-width: 1400px) {
                        color: #2e8fff;
                    }
                }
            }

            &__count {
                background: #fff;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                position: absolute;
                margin: auto;
                right: -18px;
                top: -13px;

                body.frontpage & {
                    @media only screen and (max-width: 1400px) {
                        background: #2e8fff;
                    }
                }

                span {
                    color: #2e8fff;
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: bold;

                    body.frontpage & {
                        @media only screen and (max-width: 1400px) {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .header__language {
            @include breakpoint(medium) {
                &:not(:first-child) {
                    border-left: 1px solid rgba(255,255,255,0.5);
                }
            }

            height: 45px;

            ul {
                margin-right: -5px;
                padding-top: 5px;
            }

            li {
                a {
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    display: block;
                    line-height: 40px;
                    text-align: center;
                    vertical-align: middle;
                    border-radius: 50%;
                    margin: 0 2px 0 30px;
                    font-size: 12px;
                    background: white;
                    color: $primary-color;

                    body:not(.frontpage) & {
                        background: transparent;
                        color: $white;
                    }

                    &:hover {
                        opacity: .7;
                    }

                    @include breakpoint(medium) {
                        background: transparent;
                    }
                }

                &.-active {
                    order: 3;

                    a {
                        opacity: 1;
                        color: $white !important;
                        background: $primary-color !important;
                        margin-left: 15px;

                        body:not(.frontpage) & {
                            background: $white !important;
                            color: $primary-color !important;
                        }

                        @include breakpoint(xxlarge) {
                            background: $white !important;
                            color: $primary-color !important;
                        }
                    }
                }
            }
        }
    }

    .frontpage &, .hubpage & {
        ul {
            li {
                a {
                    @include breakpoint(xxlarge) {
                        color: $white;
                    }
                }
            }
        }
    }
}

.mobilemenu {
    position: fixed;
    padding: 1.5rem 1rem;
    transform: translateY(100%);
    bottom: 0;
    z-index: 100;
    background: white;
    width: 100%;
    text-align: center;
    font-weight: bold;
    transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
    max-width: 100vw;

    @include breakpoint(xlarge) {
        display: none;
    }

    &.-active {
        transform: translateY(0%);
        transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);

        @include breakpoint(xlarge) {
            display: none;
        }

        .header__mobile-menu {
            margin: 1rem 1rem -30px;
            transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
            box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.3);
        }

        a {
            background: transparent;
            color: #2e8fff;
        }
    }

    .is-dropdown-submenu {
        position: static;
        opacity: 1;
        visibility: visible;
        width: 100%;
        display: block;
        text-align: center;
        border: none;
        margin: 0 0 .5rem;

        a {
            text-align: left !important;
            border: none !important;
            font-weight: 900 !important;
            font-size: 80% !important;
            padding: .6rem 0 !important;
            margin: 0;
        }
    }

    li {
        width:100%;

        &.-active {
            a {
                background: transparent;
                color: #2e8fff;
            }
        }
    }

    a {
        font-weight: bold;
    }
}

.menu__hubpage-link {
    border-radius: 22px;
    border: 1px solid transparent !important;
    margin-left: 25px;
    color: white !important;

    &:focus {
        color: white;
    }

    &--current-page, &--hub-subpage {
        border: 1px solid white !important;
    }

    &--mobile {
        margin: 20px 0 0 0;
        display: inline-block !important;
        padding-left: 1rem !important;
    }
}
