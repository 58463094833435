﻿.member-type-item {
    background: #F2F3F3;
    padding: 30px 30px 30px;

    &__title {
        text-transform: uppercase;
        font-size: 18px;
        display: block;
        margin: 0;

        @include breakpoint(medium) {
            display: inline-block;
        }
    }

    &__price {
        font-size: 16px;
        display: block;
        margin-bottom: 0.5rem;

        @include breakpoint(medium) {
            padding-left: 15px;
            display: inline-block;
            margin-left: 17px;
            line-height: 31px;
            border-left: 1px solid #c4c4c4;
        }
    }

    &__description {
        font-size: 14px;
        margin: 0;

        @include breakpoint(large) {
            font-size: 16px;
        }
    }

    &__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__info {
        flex-grow: 0;
        flex-shrink: 1;
        width: 100%;

        @include breakpoint(medium) {
            width: 60%;
        }

        @include breakpoint(large) {
            width: 70%;
        }
    }

    &__expander {
        height: 0;
        position: relative;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
    }

    &__expander-inner {
        padding-top: 30px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;

        @include breakpoint(medium) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__section-checkmark {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

        &:after {
            content: '';
            position: absolute;
            width: 5px;
            height: 11px;
            border-bottom: 1px solid white;
            border-right: 1px solid white;
            display: block;
            top: 50%;
            left: 50%;
            transform-origin: center;
            transform: translate(-60%, -60%) rotate(45deg);
        }
    }

    &__list-section {
        flex-grow: 0;
        flex-shrink: 0;
        box-sizing: border-box;
        width: 100%;

        @include breakpoint(medium) {
            width: calc(50% - 30px);
        }

        @include breakpoint(xlarge) {
            width: 100%;
        }

        @include breakpoint(xxlarge) {
            width: calc(50% - 30px);
        }

        h4 {
            margin-bottom: 20px;
            font-size: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 30px;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }

            @include breakpoint(xlarge) {
                margin-bottom: 30px;
            }

            @include breakpoint(xxlarge) {
                margin-bottom: 0;
            }
        }

        &--obligations {
            .member-type-item__list-item {
                &:before {
                    background-color: #380038;
                }
            }

            .member-type-item__section-checkmark {
                background-color: #380038;
            }
        }

        &--benefits {
            .member-type-item__list-item {
                &:before {
                    background-color: get-color(primary);
                }
            }

            .member-type-item__section-checkmark {
                background-color: get-color(primary);
            }
        }
    }

    &__list {
        margin: 0;
        list-style: none;
    }

    &__list-item {
        padding-left: 30px;
        position: relative;
        font-size: 14px;

        &:before {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            left: 10px;
            top: 10px;
        }

        &:not(:last-child) {
            margin-bottom: 5px;
        }

        &.member-type-item__list-item--unavailable {
            color: #B2B2B2;
            text-decoration: line-through;

            &:before {
                background-color: #B2B2B2;
            }
        }
    }

    &__toggle-btn {
        flex-grow: 0;
        flex-shrink: 0;
        cursor: pointer;
        position: relative; //absolute;
        //top: 50%;
        right: -30px;
        //transform: translateY(-50%);
        background: get-color(primary);
        height: 53px;
        width: 53px;
        color: white;

        @include breakpoint(medium) {
            width: auto;
            padding: 0 60px;
        }

        @include breakpoint(xlarge) {
            width: 53px;
            padding: 0;
        }

        @include breakpoint(xxlarge) {
            right: -50px;
            padding: 0 60px;
            width: auto;
        }

        &:before {
            content: '';
            width: 14px;
            height: 14px;
            border-left: 2px solid white;
            border-top: 2px solid white;
            transform-origin: 31% 31%;
            transform: translateY(25%) rotate(225deg);
            display: inline-block;
            transition: transform 0.2s ease-in-out 0.1s;
            margin-left: 5px;

            @include breakpoint(medium) {
                margin-right: 20px;
                margin-left: 0;
            }

            @include breakpoint(xlarge) {
                margin-right: 0;
                margin-left: 5px;
            }

            @include breakpoint(xxlarge) {
                margin-right: 20px;
                margin-left: 0;
            }
        }

        &__arrow {
        }

        &__text {
            display: none;

            @include breakpoint(medium) {
                display: inline-block;
            }

            @include breakpoint(xlarge) {
                display: none;
            }

            @include breakpoint(xxlarge) {
                display: inline-block;
            }
        }
    }





    &--open {
        .member-type-item__toggle-btn {

            &:before {
                transform: translateY(25%) rotate(45deg);
            }
        }
        /*.member-type-item__expander-inner {
            position: static;
        }*/
    }
}
