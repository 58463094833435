﻿.long-content-section {
    padding: 20px 1rem;

    @include breakpoint(large) {
        padding: 50px 1rem;
    }

    &__cta {

        .button {
            border-radius: 50px;
            text-transform: uppercase;
            padding: 20px 35px;
            font-size: 18px;
            font-weight: bold;
        }
    }

    &__sidebar {
        margin: 0 0 1.5rem;

        .long-content-section__sidebar-heading {
            padding: 1rem 1rem 0;

            @include breakpoint(medium) {
                padding: 1.55rem 1.55rem 0;
            }
        }

        .long-content-section__cta {
            padding: 0rem 1rem 1rem;

            .button {
                margin: 0;
            }

            @include breakpoint(medium) {
                padding: 0rem 1.55rem 1.55rem;
            }
        }

        .long-content-section__sidebar-bodytext {
            padding: 0 1rem;

            @include breakpoint(medium) {
                padding: 0 1.5rem;
            }
        }
    }

    .long-content-section__content, &__content {
        padding-right: 0;

        @include breakpoint(large) {
            padding-right: 2rem;
        }

        @include breakpoint(xxlarge) {
            padding-right: 4rem;
        }
    }

    &__body {
        img {
            width: 100%;
            height: auto;
        }
    }
}
