﻿.event-time {
    background: map-get($foundation-palette, "primary");
    text-align: center;
    font-size: 20px;
    line-height: 1;

    &__date {
        color: white;
        font-weight: 300;
        text-transform: uppercase;
        padding: 40px 0;

        > * {
            display: block;
        }
    }

    &__date-numeric {
        font-weight: bold;
        font-size: 34px;
        margin: 5px 0;
    }

    &__duration {
        padding: 15px;
        color: map-get($foundation-palette, "primary");
        background: #f0f0f0;
        font-weight: 800;
    }
}
