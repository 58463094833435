﻿.member-type-overview-section {
    padding: 0 0.5rem;

    &__list {
        list-style: none;
        margin: 0;
    }

    &__list-item {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}
