﻿
@media only print {

    div {
        break-inside: avoid;
    }

    .favourites-menu {

        &__top-content {
            button {
                display: none;
            }
        }

        &__cta {
            display: none;
        }

        ul {
            list-style: none;

            .method-list {
                width: 33.33333%;
                padding: 0 0.6rem 0 0;

                .method-list-item__cta {
                    display: none;
                }

                a[href]:after {
                    content: " ";
                }

                .method-list-item {
                    display: block;

                    h4, p {
                        page-break-inside: avoid;
                    }
                }
            }
        }
    }
    * {
        transition: none !important;
    }
}
