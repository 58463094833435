﻿.menu-contact {
    background: $light-gray;
    padding: 15px 0 15px 0;
    text-align: center;

    @include breakpoint(medium) {
        text-align: initial;
        padding: 75px 0 55px 0;
    }

    &__menu {
        display: flex;
        list-style: none;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
        flex-direction: column;

        @include breakpoint(medium) {
            flex-direction: row;
            padding-top: 30px;
            padding-bottom: 50px;
        }

        > li {
            flex-grow: 1;
            padding-right: 1rem;

            > a {
                font-weight: bold;
                font-size: 20px;
                display: block;
                color: $black;
            }
        }
    }

    &__submenu {
        list-style: none;
        margin: 0;

        li {
            margin-top: 12px;
        }
    }

    &__content {
        color: $primary-color;

        @include breakpoint(medium) {
            padding-left: 15px;
        }
    }

    &__phone {
        font-size: 170%;
    }

    &__container {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;

        @include breakpoint(medium) {
            justify-content: flex-end;
            border-left: 2px solid #e2e2e2;
            padding-top: 30px;
            padding-bottom: 50px;
        }
    }

    &__heading {
        font-size: 20px;
        font-weight: bold;
        color: $black;
    }
}
