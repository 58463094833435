﻿@font-face {
    font-family: 'Juli Sans';
    src: url('/css/fonts/JuliSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Juli Sans';
    src: url('/css/fonts/JuliSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@charset "UTF-8";

@font-face {
  font-family: "cocplayfulminds";
  src:url("/css/fonts/cocplayfulminds.eot");
  src:url("/css/fonts/cocplayfulminds.eot?#iefix") format("embedded-opentype"),
    url("/css/fonts/cocplayfulminds.woff") format("woff"),
    url("/css/fonts/cocplayfulminds.ttf") format("truetype"),
    url("/css/fonts/cocplayfulminds.svg#cocplayfulminds") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "cocplayfulminds" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "cocplayfulminds" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow:before {
  content: "\61";
}
.icon-angle-right:before {
  content: "\62";
}
.icon-facebook-official:before {
  content: "\63";
}
.icon-instagram:before {
  content: "\64";
}
.icon-linkedin-square:before {
  content: "\65";
}
.icon-facebook-circled:before {
  content: "\66";
}
.icon-instagram-circle:before {
  content: "\67";
}
.icon-linkedin-circled:before {
  content: "\68";
}
.icon_heart_alt:before {
    content: "\e030";
}
.icon_heart:before {
    content: "\e089";
}
