﻿.info-list-macro {
    h6 {
        font-size: 24px;

        @include breakpoint(medium) {
            font-size: 30px;
            padding-top: 10px;
        }
    }

    ol {
        list-style: none;
        padding-left: 25px;

        @include breakpoint(medium) {
            padding-left: 40px;
        }
    }

    ol li {
        padding-left: 0px;
        counter-increment: list-counter;
        position: relative;
        margin-bottom: 40px;

        @include breakpoint(medium) {
            padding-left: 18px;
        }
    }

    ol li::before {
        content: counter(list-counter);
        font-family: "Juli Sans";
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        position: absolute;
        --size: 40px;
        left: calc(-1 * var(--size) - 10px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: 0;
        background: #387CB3;
        border-radius: 50%;
        text-align: center;

        @include breakpoint(medium) {
            font-size: 24px;
            --size: 55px;
        }
    }
}
