﻿.favourites-menu {
    width: 100%;
    right: -100%;
    position: fixed;
    z-index: 4;
    transition: right 0.5s ease-in;
    background: #fff;
    box-shadow: 0 0 25px 0 rgba(0,0,0,0.1);
    height: 100%;
    top: 0;
    padding: 2%;
    overflow-x: hidden;

    @include breakpoint(medium) {
        width: 100%;
        right: -100%;
        padding: 4%;
    }

    @include breakpoint(large) {
        padding: 6%;
    }

    @include breakpoint(xlarge) {
        max-width: 1166px;
        right: -1166px;
    }

    &--open {
        width: 100%;
        height: 100%;
        right: 0;
        transition: right 0.4s ease-out;
        overflow-y: auto;
        padding-bottom: 50px;
        z-index: 999;

        .favourites-menu__cta {
            margin-left: -4%;
            margin-bottom: -0.2%;
        }

        @include breakpoint(medium) {
            max-width: 100%;
            position: fixed;
        }

        @include breakpoint(xlarge) {
            max-width: 1166px;
        }
    }

    &__heading {
        text-align: left;
        margin-top: 20px;

        @include breakpoint(medium) {
            margin-top: 0px;
            font-size: 40px;
        }
    }

    &__top-content {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: -30px;
        position: absolute;
        margin-top: 60px;

        @include breakpoint(medium) {
            margin-bottom: 25px;
            position: initial;
            margin-top: unset;
        }

        button {
            text-transform: uppercase;
            opacity: 0.8;
            cursor: pointer;
            border-left: 1px solid rgba(0,0,0,0.1);
            margin-left: 8px;
            padding-left: 10px;
            color: #2D8FFF;

            @include breakpoint(medium) {
                margin-left: 13px;
                padding-left: 13px;
            }

            &:hover {
                color: #2D8FFF;
            }
        }
    }

    &__cta {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-left: -0.1%;
        margin-bottom: -0.2%;
        justify-content: center;
        padding: 15px 25px 15px 25px;
        box-shadow: 0 -5px 5px -5px rgba(0,0,0,0.1);
        display: flex;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        z-index: 1000;
        background: #fff;

        @include breakpoint(large) {
            background: unset;
            position: unset;
            bottom: unset;
            width: unset;
            margin-left: unset;
            margin-bottom: unset;
            z-index: unset;
            display: unset;
            margin: unset;
            padding: unset;
            box-shadow: unset;
        }

        .button {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #2D8FFF;
            background: #F0F0F0;
            margin: 0;
            border-radius: 100px;
            text-transform: uppercase;
            padding: 0.6em 1.5em;
            border-left: none;

            &:hover, &:focus {
                background-color: #dbdbdb;
            }

            svg {
                margin-right: 10px;
                width: 27px;
                height: 27px;
                margin-top: 4px;
            }
        }
    }

    ul {
        list-style: none;

        .method-list {
            padding: 0;

            @include breakpoint(medium) {

                &:nth-child(odd) {
                    padding: 0 0.6rem 0 0;
                }

                &:nth-child(even) {
                    padding: 0 0 0 0.6rem;
                }
            }
        }

        .method-list-item {
            margin-bottom: 50px;

            a {
                padding: 0;
            }

            h4 {
                color: #000;
                text-align: left;
            }

            &__text {
                font-weight: 400;
                color: #000;
                text-align: left;
            }

            &__cta {
                font-weight: 400;
                text-align: left;
            }
        }
    }
}

.favourite-overlay--open {
    background: rgba(0, 0, 0, .7);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

@media print {
    div {
        break-inside: avoid;
        display: table;
    }
}
