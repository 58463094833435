﻿$feature-padding: 50px;

.coc-feature-section {
    background: transparent !important;
    padding: 1rem 0rem;

    .grid-container {
        @include breakpoint(large) {
            paddding:0 56px!important;
        }
    }

    @include breakpoint(xlarge) {
        padding-top: 40px;
    }

    &--expanded {
        padding: 0;

        @include breakpoint(xlarge) {
            padding: $feature-padding 0;
        }
    }

    &__media-wrapper {
        display: flex;
        align-content: center;
        align-items: center;
    }

    &__cta {
        a {
            margin: 0;
            border-radius: 100px;
            text-transform: uppercase;
            padding: 1.2em 1.5em;
            transition: all ease-in-out 350ms;

            &:hover {
                opacity: .8;
            }
        }
    }

    &__heading {
        margin: 0 0 1.2rem;

        h2 {
            margin: 0;

            @include breakpoint(medium down) {
                &.h3 {
                    font-size: 1.5rem;
                }
            }
        }
    }

    &__body {
        margin: 0 0 1.55rem;

        p {
            &:last-child {
                margin: 0;
            }
        }
    }

    &__media {
        position: relative;
        max-width: 100%;
        width:100%;

        .slick-list {
            overflow: hidden;
        }

        img {
            width: 100%;
            transform: scale(1.02);
        }

        .slick-slide {
            overflow: hidden;
            height: auto;
        }

        &:before {
            background-color: inherit;
            position: absolute;
            //bottom: -$feature-padding;
            bottom: -40px;
            width: 100%;
            height: 100%;
            z-index: -1;
            content: '';
        }
    }

    &__content {
        padding: 2rem 1.5rem 1.5rem;

        @include breakpoint(xlarge) {
            padding: $feature-padding;
        }
    }

    .slick-dots {
        position: absolute;
        top: 50%;
        width: 16px;
        margin: 0;
        transform: translateY(-50%);

        li {
            width: 16px;
            height: 16px;
            background: $light-gray;
            border-radius: 50%;
            cursor: pointer;
            list-style: none;
            margin: 8px 0;
            transition: all ease-in-out 200ms;

            &.slick-active {
                background: get-color(primary);
            }

            &:hover {
                background: lighten(get-color(primary), 20%);
            }

            button {
                font-size: 1;
                opacity: 0;
            }
        }
    }
    /* 
        Alternating functionality. Be careful if you want to change things here. 
    */
    &:nth-child(odd) {
        .grid-x {
            flex-direction: row;
        }

        .coc-feature-section__media:before {
            //right: -$feature-padding;
            right: -40px !important;
        }

        .coc-feature-section__content {
            padding-left: $feature-padding;
        }

        .slick-dots {
            left: -36px;
        }

        & .coc-feature-section--expanded {
            margin-left: $feature-padding;

            .coc-feature-section__media {
                margin-left: -$feature-padding;
            }

            @include breakpoint(xlarge) {
                .coc-feature-section__content {
                    padding-right: $feature-padding;
                }
            }
        }
    }

    &:nth-child(even) {
        .grid-x {
            flex-direction: row-reverse;
        }

        .coc-feature-section__media:before {
            //left: -$feature-padding;
            left: -40px;
        }

        .coc-feature-section__content {

            @include breakpoint(xlarge) {
                padding-right: $feature-padding;
            }
        }

        .slick-dots {
            right: -36px;
        }

        & .coc-feature-section--expanded {
            margin-right: $feature-padding;

            .coc-feature-section__media {
                margin-right: -$feature-padding;

                &:before {
                    display: none;
                }
            }

            @include breakpoint(xlarge) {
                .coc-feature-section__content {
                    padding-right: 0;
                    padding-left: $feature-padding;
                }
            }
        }
    }

    &--left {
        .grid-x {
            flex-direction: row !important;
        }

        @include breakpoint(xlarge down) {
            .coc-feature-section__media-wrapper {
                padding-left: 3rem;
                margin: 0;
            }
        }

        .coc-feature-section__media:before {
            //right: -$feature-padding !important;
            right: -40px !important;
            left: auto !important;

            @include breakpoint(xlarge down) {
                right: -20px !important;
                bottom: -20px;
            }
        }

        @include breakpoint(xlarge) {
            .coc-feature-section__content {
                padding-right: 0 !important;
                padding-left: $feature-padding !important;
            }
        }

        .slick-dots {
            right: auto !important;
            left: -36px !important;

            @include breakpoint(xlarge down) {
                left: -30.5px !important;
            }
        }

        & .coc-feature-section--expanded {
            margin-right: 0 !important;
            margin-left: $feature-padding !important;

            @include breakpoint(xlarge down) {
                .coc-feature-section__media-wrapper {
                    padding-left: 0rem;
                    margin: 0;
                }
            }

            .coc-feature-section__media {
                margin-right: 0 !important;
                margin-left: -$feature-padding !important;

                &:before {
                    display: none;
                }

                @include breakpoint(large down) {
                    margin-left: 0 !important;
                }
            }

            @include breakpoint(xlarge) {
                .coc-feature-section__content {
                    padding-right: $feature-padding !important;
                    padding-left: 20px !important;
                }
            }
        }
    }

    &--right {

        @include breakpoint(xlarge) {
            //padding-right: 60px;
        }

        .grid-x {
            flex-direction: row-reverse !important;
        }

        @include breakpoint(xlarge down) {
            .coc-feature-section__media-wrapper {
                padding-right: 3rem;
                margin: 0;
            }
        }

        .coc-feature-section__media:before {
            right: auto !important;
            //left: -$feature-padding !important;
            left: -40px !important;

            @include breakpoint(xlarge down) {
                left: -20px !important;
                bottom: -20px;
            }
        }

        @include breakpoint(xlarge) {
            .coc-feature-section__content {
                padding-right: $feature-padding !important;
                padding-left: 0 !important;
            }
        }

        .slick-dots {
            left: auto !important;
            right: -36px !important;

            @include breakpoint(xlarge down) {
                right: -28.5px !important;
            }
        }

        & .coc-feature-section--expanded {
            margin-right: $feature-padding !important;
            margin-left: 0 !important;

            .coc-feature-section__media {
                margin-right: -$feature-padding !important;
                margin-left: $feature-padding !important;

                @include breakpoint(large down) {
                    margin-left: 0 !important;
                }

                &:before {
                    display: none;
                }
            }

            @include breakpoint(xlarge) {
                .coc-feature-section__content {
                    padding-right: 0 !important;
                    padding-left: $feature-padding !important;
                }
            }

            @include breakpoint(xlarge down) {
                .coc-feature-section__media-wrapper {
                    padding-right: 0rem;
                    margin: 0;
                }
            }
        }
    }
}