﻿.contact-section {
    color: $primary-color;
    padding: 1rem;

    @include breakpoint(xlarge) {
        padding: 1.5rem;
    }

    &__wrapper {
        background-color: $light-gray;
    }

    .contact-col {
        padding: 1.5rem 1rem 0;

        &:last-child {
            padding-bottom: 2rem;
        }

        @include breakpoint(xlarge) {
            padding: 0;

            &:last-child {
                padding-bottom: 0rem;
            }
            
        }

    }

    &__heading {
        margin: 0 0 1.25rem;

        h4 {
            font-size: 1rem;
            font-weight: 100;
            font-weight: 600;
            margin-right: .6rem;
            font-size: .8rem;
            margin: 0 0 .25rem;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    &__name {
        font-weight: bold;
        line-height: 1;
        font-size: 1.6rem;
    }

    &__media {
        text-align: left;
        min-height: 350px;
        background-size: cover!important;
        background-position:center; 
    }

    &__title {
        margin: .5rem 0 0;
        line-height: 1;
        font-size: 1rem;
    }

    &__phone {
        line-height: 1;
        margin: 0 0 1.5rem;
    }

    &__email {
        line-height: 1;
    }

    &__heading-wrapper {
        
        text-align: center;
        vertical-align: middle;

        @include breakpoint(xlarge) {
            border-right: 1px solid #c7c7c7;
        }

    }

    &__phone-wrapper {
        text-align: center;
        vertical-align: middle;
        font-size: 1.15rem;

        > div {
            display: flex;
            justify-content: center;
            flex-direction: column;

            span {
                font-weight: 600;
                margin-right: .6rem;
                font-size: .8rem;
                margin: 0 0 .25rem;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

        }

    }

    a {
        color: inherit;
    }

    // for small screen
    @media screen and (max-width: 46em) {

        &__media {
            text-align: center;
        }

        &__heading-wrapper {
            border-right: 0px;
            padding-top: 20px;
        }

        &__phone-wrapper {
            border-right: 0px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
