﻿.events-overview-section {
    background: white;

    &__toggle-bar {
        height: 60px;
        display: flex;
        background: #f0f0f0;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #b5b5b5;

        @include breakpoint(xlarge) {
            margin-top: -50px;
        }

        > * {
            padding: 0 10px;
            cursor: pointer;

            &.active {
                font-weight: bold;
                color: map-get($foundation-palette, "primary");
            }
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        display: none;
        padding: 20px 1rem;

        @include breakpoint(large) {
            padding: 70px 1rem;
        }

        &--active {
            display: block;
        }

        > * {
            &:not(:last-child) {
                padding-bottom: 50px;
                border-bottom: 1px solid #f0f0f0;
                margin-bottom: 50px;
            }
        }
    }

    &__event-item {
        .event-time {
            width: 160px;
            margin-right: 40px;
        }
    }
}
