﻿.image-grid-section {

    .grid-container {
        padding: 0; 
    } 

    &__item { 
        min-height: 400px;
        display: flex;
        position: relative;
        overflow: hidden;

        @include breakpoint(xlarge) {
            min-height: 400px;
        }

        @include breakpoint(xxlarge) {
            min-height: 450px;
        }
    } 

    a {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: black;
            opacity: 0;
            transition: all .35s cubic-bezier(0.7, 0, 0.3, 1);
        }

        &:hover {
            &:after {
                opacity: .2;
                transition: all .35s cubic-bezier(0.7, 0, 0.3, 1);
            }
        }
    }

    &__heading {
        opacity: 1;
        transition: all .8s cubic-bezier(0.7, 0, 0.3, 1);
        margin: 0 0 1rem;

        a.image-grid-section__item:hover & {
            transition: all .8s cubic-bezier(0.7, 0, 0.3, 1);
        }

        h3 {
            margin: 0;

            @include breakpoint(xlarge down) {
                font-size: 1.8rem;
            }

            @include breakpoint(large down) {
                font-size: 1.35rem;
            }
        }
    }

    &__body {
        opacity: 1;
        transition: all .8s cubic-bezier(0.7, 0, 0.3, 1);

        a.image-grid-section__item:hover & {
            transition: all .8s cubic-bezier(0.7, 0, 0.3, 1);
        }

        p {

            &:last-child {
                margin: 0;
            }
        }
    }

    &__media {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform-origin: 50% 50%;
        transform: scale(1.01);
        border-radius: 0px;
        transition: all .8s cubic-bezier(0.7, 0, 0.3, 1);
        overflow: hidden;
        filter: blur(0px) grayscale(0%);

        a.image-grid-section__item:hover & {
            //transform: scale(.9);
            filter: blur(0px) grayscale(15%);
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__container {
        position: relative;
        z-index: 2;
        padding: 20px;

        @include breakpoint(xxlarge) {
            padding: 30px;

            .large-6 &, .auto & {
                padding: 30px 40px;
            }
        }
    }

    @include breakpoint(medium down) {
        .-margin-hack {
            margin-left: 0.1px;
        }
    }
}

.one-two-image-grid-section {
    .withLink {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: black;
            opacity: 0;
            -webkit-transition: all 0.35s cubic-bezier(0.7, 0, 0.3, 1);
            transition: all 0.35s cubic-bezier(0.7, 0, 0.3, 1);
        }

        &:hover:after {
            opacity: .2;
            -webkit-transition: all 0.35s cubic-bezier(0.7, 0, 0.3, 1);
            transition: all 0.35s cubic-bezier(0.7, 0, 0.3, 1);
        }
    }
}