﻿.hero-cirkel-section {
    margin-bottom: 5rem;
    min-height: 485px;

    @media only screen and (max-width: 950px) and (min-width: 770px) {
        margin-top: 3rem;
    }

    @include breakpoint(large down) {
        margin-bottom: 13rem;
        margin-top: 4rem;
    }

    @include breakpoint(xlarge down) {
        margin-bottom: 8rem;
    }

    @include breakpoint(medium down) {
        margin-bottom: 1rem;
    }

    &__media-wrapper {
        @include breakpoint(large down) {
            position: absolute;
            top: 0%;
            right: 0%;
            padding: 20px 30px 20px;
            height: 100%;
            display: flex;
            align-items: center;
            width: 100% !important;
            justify-content: center;
        }
    }

    &__content-wrapper {
        display: flex;
        align-items: center;

        @include breakpoint(large down) {
            min-height: 700px;
            padding: 30px 30px;
            align-items: baseline;
            justify-content: center;
            max-width: 500px;
            margin: auto;
        }
    }

    &__content {
        padding: 0;
    }

    &__body, &__cta {
        max-width: 100%;
        margin: 0 auto;
    }

    &__cta {
        .button {
            border-radius: 50px;
            text-transform: uppercase;
            padding: 20px 35px;
            font-size: 18px;

            &:hover {
                -webkit-transition: all 0.35s cubic-bezier(0.7, 0, 0.3, 1);
                transition: all 0.35s cubic-bezier(0.7, 0, 0.3, 1);
                -webkit-transition-delay: .0s;
                transition-delay: .0s;
                -webkit-box-shadow: 0px 11px 11px rgba(0,0,0,0.11);
                box-shadow: 0px 11px 11px rgba(0,0,0,0.11);
            }
        }

        @include breakpoint(medium down) {
            margin-top: 1rem;
        }
    }

    &__body {
        font-size: 20px;

        p {
            font-weight: bold;

            @include breakpoint(xlarge) {
                font-weight: 100;
            }
        }
    }

    &__media {
        position: relative;
        overflow: hidden;
    }

    &__headline {
        font-size: 3rem;

        @include breakpoint(xlarge) {
            font-size: 5rem;
        }

        @include breakpoint(xlarge down) {
            font-size: 4.5rem;
            line-height: 4.5rem;
        }

        @include breakpoint(large down) {
            font-size: 3rem;
            line-height: 3rem;
        }
    }

    &__body {
        margin: 1.5rem 0 1.8rem;
        max-width: 500px;

        @include breakpoint(large down) {
        }

        @include breakpoint(medium down) {
            display: none;
        }
    }

    .thumbnail__play-button {
        cursor: pointer;
        background: #2e8fff;
        width: 125px;
        height: 125px;
        color: #fff;
        position: absolute;
        top: 430px;
        right: 640px;
        border-radius: 50%;
        padding: 47px 40px 47px 55px;
        z-index: 1;

        @include breakpoint(xlarge down) {
            top: 475px;
            right: 330px;
        }

        @include breakpoint(large down) {
            width: 95px;
            height: 95px;
            right: 35px;
            left: unset;
            padding: 32px 32px 32px 38px;
            bottom: -145px;
        }

        @media only screen and (max-width: 1400px) and (min-width: 1046px) {
            top: 475px;
            right: 615px;
        }

        @media only screen and (max-width: 1300px) and (min-width: 1046px) {
            right: 495px;
        }

        @media only screen and (max-width: 1200px) and (min-width: 1046px) {
            right: 355px;
        }

        .arrow-icon {
            margin: 0px 0 0px 0;
            width: 0px;
            height: 0px;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 24px solid white;
        }

        &:hover {
            -webkit-transition: all 0.35s cubic-bezier(0.7, 0, 0.3, 1);
            transition: all 0.35s cubic-bezier(0.7, 0, 0.3, 1);
            -webkit-transition-delay: .0s;
            transition-delay: .0s;
            -webkit-box-shadow: 0px 11px 11px rgba(0,0,0,0.11);
            box-shadow: 0px 11px 11px rgba(0,0,0,0.11);
        }
    }

    .grid-container {
        position: relative;

        .circle {
            content: "";
            position: absolute;
            top: -400px - 168px; // -design offset - header height
            left: 410px;
            width: 1200px;
            height: 1200px;
            border-radius: 50%;
            background: transparent;
            z-index: -1;
            background: get-color(primary);
            background-size: cover;
            background-position: center center;
            box-shadow: inset 0 0 0 1000px rgba(46,143,255,0.34);

            @include breakpoint(xlarge down) {
                top: -124px;
                left: 550px;
                width: 800px;
                height: 800px;
            }

            @include breakpoint(large down) {
                width: 800px;
                height: 800px;
                left: 85%;
                right: initial;
                -webkit-transform: translateX(-50%) !important;
                transform: translateX(-50%) !important;
                top: -250px;
            }
        }
    }


    .placeholder-media {
        position: absolute;
        top: -940px;
        left: 410px;
        width: 1130px;
        height: 1190px;
        z-index: -1;

        @include breakpoint(xlarge down) {
            left: 430px;
        }

        @include breakpoint(large down) {
            left: 0px;
        }

        @include breakpoint(medium down) {
            left: -150px;
        }

        video {
            clip-path: circle(46%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 415px;
            object-fit: cover;
            padding: 0;
            margin: 0;
            filter: brightness(0.9) contrast(0.9) sepia(0.1);

            @include breakpoint(xlarge down) {
                clip-path: circle(35%);
                top: 655px;
            }

            @include breakpoint(large down) {
                clip-path: circle(46%);
                top: 490px;
            }

            @include breakpoint(medium down) {
                clip-path: unset;
                top: 600px;
            }


            @media only screen and (max-width: 950px) and (min-width: 770px) {
                clip-path: circle(36%);
                top: 496px;
            }
        }
    }

    &__modal {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(0,0,0,.5);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 101;
        visibility: hidden;
        opacity: 0;
        transition: visibility .3s linear,opacity .3s linear;

        &--active {
            visibility: visible;
            opacity: 1;
        }

        .modal__close {
            position: absolute;
            top: -30px;
            right: -30px;
            background: #fff;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            cursor: pointer;

            .cross {
                &:after {
                    position: absolute;
                    top: -7;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    content: "\00D7";
                    font-size: 40px;
                    color: #000;
                    text-align: center;
                }
            }
        }
    }

    .modal__vimeo {

        .modal__close {
            top: 34px;
            right: -20px;
        }
    }


    .modal__inner {
        position: relative;

        .responsive-container {
            position: relative;
            margin: auto 15%;
        }
    }

    .modal__youtube, .modal__vimeo {
        .responsive-container {
            position: relative;
            margin: auto;

            iframe {
                position: unset;
                height: 80%;
                width: 1150px;
                aspect-ratio: 1.5;

                @include breakpoint(xlarge up) {
                    height: 90%;
                    width: 100%;
                }

                @include breakpoint(xlarge down) {
                    height: 88%;
                    width: 940px;
                }

                @include breakpoint(large down) {
                    height: 89%;
                    width: 710px;
                }

                @include breakpoint(medium down) {
                    height: 80%;
                    width: 400px;
                }
            }
        }
    }
}
