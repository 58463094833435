﻿    .is-dropdown-submenu-parent {
    &.opens-right {
        > .is-dropdown-submenu {
            top: auto !important;
            left: 0 !important;

            li {
                a {
                    color: $black;
                    text-align: left;
                    font-size: 90%;
                    font-weight: normal;

                    &:hover {
                        background: rgba(get-color(primary), 0.1);
                    }
                }

                + li a {
                    border-top: 1px solid rgba(get-color(primary), 0.1);
                }
            }
        }
    }

    &.opens-left {
        > .is-dropdown-submenu {
            top: auto !important;
            right: 0 !important;

            li {
                a {
                    color: $black;
                    text-align: left;
                    font-size: 90%;
                    font-weight: normal;

                    &:hover {
                        background: rgba(get-color(primary), 0.1);
                    }
                }

                + li a {
                    border-top: 1px solid rgba(get-color(primary), 0.1);
                }
            }
        }
    }
}

.mobilemenu {

    li {
        width: 100%;
        text-align: left;

        > a {
            padding-left: 0;
        }
    }

    li.is-dropdown-submenu-parent {

        &:after {
            content: "";
            width: 8px;
            height: 8px;
            display: inline-block;
            border-right: solid 2px;
            border-bottom: solid 2px;
            transform: rotate(45deg);
            border-color: #2f8fff;
            position: absolute;
            right: 20px;
            top: 16px;
        }

        > a {
            width: auto;
            display: inline-block;
            position: relative;
            padding-left: 0;
        }
    }

    .is-dropdown-submenu {
        display: none;
    }
}