﻿.contact-macro__sidebar {
    border: none!important;
}

.contact-macro {
    text-align: center;
    
    &__sidebar {
        border: none!important;
    }

    &__media {
        img {
            width: 100%;
        }
    }

    &__heading {
        padding: 2rem 1rem 0;   
        text-align: center;     

        h4 {
            font-size: 1rem;
            font-weight: 100;
            font-weight: 600;
            margin-right: .6rem;
            font-size: .8rem;
            margin: 0 0 .25rem;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

    }
    
    &__name {
        font-weight: bold;
        line-height: 1;
        font-size: 1.4rem;

        @include breakpoint(large) {
            font-size: 1.6rem;
        }

    }

    &__title {
        margin: .5rem 0 0;
        line-height: 1;
        font-size: .9rem;

        @include breakpoint(large) {
            font-size: 1rem;
        }

    }

    &__name-wrapper {
        text-align: center;
    }

    &__phone-wrapper {
        padding: 2rem 1rem 2rem;
        text-align: center;
        font-weight: 600;
        margin-right: .6rem;
        font-size: .8rem;
        margin: 0 0 .25rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: inline-block;
        width: 100%;

        a {
            font-weight: normal;
            letter-spacing: 0;
            font-size: .9rem;
            display: inline-block;
            width: 100%;
            text-transform: none;
            margin: .5rem 0 0;

            @include breakpoint(large) {
                font-size: 1.1rem;
            }
   
        }

    }

    &__phone {
        line-height: 1;
        margin: 0 0 1.5rem;
    }

    &__email {
        line-height: 1;
    }
    // for small screen
    @media screen and (max-width: 46em) {
        &__sidebar-wrapper {
            text-align: center;
        }

        &__media {
            text-align: center;
        }

        &__heading {
            text-align: center;
        }

        &__name-wrapper {
            border-right: 0px;
            padding-top: 20px;
            text-align: center;
        }

        &__phone-wrapper {
            border-right: 0px;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;
        }
    }
}
