﻿.method-list-item {

    @media print {
        display: inline;

        h4, p {
            page-break-inside: avoid;
        }
    }

    position: relative;
    margin-bottom: 30px;

    @include breakpoint(medium) {
        margin-bottom: 80px;
    }

    &__text {
        min-height: 70px;
    }

    a {
        text-decoration: none;
        color: #000;

        .method-list-item__image {
            margin-bottom: 20px;
        }

        h4 {
            font-size: 20px;

            @include breakpoint(medium) {
                font-size: 30px;
            }
        }

        img {
            width: 100%;
        }
    }

    &__cta .button {
        color: #fff;
        margin: 0;
        border-radius: 100px;
        text-transform: uppercase;
        font-size: 14px;
        padding: 0.95em 1.8em;

        @include breakpoint(medium) {
            font-size: 16px;
            padding: 1.05em 2.7em;
        }
    }
}
