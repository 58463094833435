﻿.heading-section {
    padding: 60px 20px 50px 20px;
    text-align: center;
    position: relative;
    //border-top: solid 1rem white;
    //border-bottom: solid 1rem white;

    @include breakpoint(large) {
        padding: 100px 20px 80px 20px;
        border: none;
    }

    &.heading-section-method {
        margin-bottom: 0px;
    }
}
