﻿.umbraco-forms {
    &-label {
        font-weight: bold;
    }

    &-form {
        .btn {
            background: white;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            padding: 3px 10px;
        }

        .field-validation-error {
            display: block;
            color: red;
        }

        legend {
            font-weight: bold;
        }

        input[type="text"] {
            margin-bottom: 0;
        }

        input[type="checkbox"] {
            display: none !important;
        }

        input[type="checkbox"] + label {
            padding-left: 20px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 14px;
                height: 14px;
                border: 1px solid #d9d9d9;
                border-radius: 20%;
            }
        }

        input[type="checkbox"]:checked + label {
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 5px;
                width: 4px;
                height: 7px;
                border-bottom: 1px solid black;
                border-right: 1px solid black;
                z-index: 1;
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }

    &-field {
        margin-bottom: 20px;
    }
}
