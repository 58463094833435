﻿.method-overview-page {
    /*** Filter ***/
    &__filter {
        background: #F0F0F0;
        margin-bottom: 30px;

        @include breakpoint(medium) {
            margin-bottom: 70px;
        }

        &__btn {
            .button {
                display: flex;
                align-items: center;
                color: #000;
                margin: auto 0.4rem;
                border-radius: 100px;
                background: #fff;
                padding: 0.5em 1.5em;
                font-size: 18px;

                svg {
                    height: 25px;
                    width: 25px;
                    margin-right: 10px;
                }
            }

            @include breakpoint(medium) {
                display: none;
            }
        }

        .filter-item {
            padding: 0 15px;
            border-right: 1px solid rgba(0,0,0,0.1);
            cursor: pointer;
            position: relative;
            display: none;

            p {
                margin-bottom: 0.2rem;
            }

            &:last-child {
                display: flex;
            }

            @include breakpoint(medium) {
                align-items: center;
                padding: 0 36px;
                display: flex;

                @media only screen and (max-width: 800px) {
                    padding: 0 20px;
                }
            }

            svg {
                transform: rotate(90deg);
                margin-left: 5px;
                width: 16px;
                height: 20px;
                stroke: #000;
            }

            &__dropdown {
                display: none;
                position: absolute;
                flex-direction: column;
                border-radius: 4px;
                top: 100%;

                &.category {
                    left: 203px;
                }

                &.age {
                    left: 362px;
                }

                &.display {
                    background: #fff;
                    padding: 40px 40px 15px 40px;
                    z-index: 3;
                    gap: 0 50px;
                    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
                    display: grid;
                    grid-auto-flow: column;
                    grid-template-rows: repeat(6, auto);
                }

                .input-container {
                    display: flex;
                    flex-direction: row;

                    input {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        margin-bottom: 24px;
                        background: #eee;
                        border: none;
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-size: contain;
                        appearance: none;
                        -webkit-print-color-adjust: exact;

                        &:checked {
                            background-color: #2D8FFF;
                            border: none;
                            background: #2D8FFF url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="13.744" height="11.391"><path data-name="Path 340" d="M12.353 1.391l-6.22 9L1.414 5.67" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>') center / contain no-repeat;
                            background-size: auto;
                        }
                    }

                    label {
                        font-size: 18px;
                    }
                }
            }

            &.display {
                color: #2D8FFF;

                svg {
                    stroke: #2D8FFF !important;
                    transform: rotate(270deg);
                    transition: transform .2s ease;
                }

                .filter-item__dropdown {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    background: #fff;
                    position: absolute;
                    padding: 40px;
                    margin: 26px -36px;
                    box-shadow: 0 0 25px 0 rgba(0,0,0,0.1);
                }
            }
        }

        &__reset-filter {
            &.filter-item {
                align-items: center;
                display: none;

                @include breakpoint(medium) {
                    display: flex;
                }

                svg {
                    transition: transform 0.5s ease;

                    @include breakpoint(medium) {
                        width: 25px;
                        height: 25px;
                        margin-right: 12px;
                    }
                }
            }

            &:hover svg {
                transform: rotate(300deg);
            }
        }

        &__age {
            &.filter-item {
                border-right: none;
            }
        }

        &__results {
            margin-left: auto;
            font-size: 12px;

            @include breakpoint(medium) {
                font-size: 18px;
            }

            &.filter-item {
                border-right: none;
                cursor: unset;

                p {
                    margin-bottom: 0px;
                }
            }
        }

        .grid-container {
            position: relative;
            height: 105px;
            display: flex;
            justify-content: left;
            flex-direction: row;
            align-items: center;

            @include breakpoint(medium) {
                height: 80px;
            }

            .show-result-btn {
                display: none;
            }
        }

        &__mobile-filter {
            display: flex;
            height: 80px;
            width: 100%;
            background: rgba(240,240,240,0.5);
            align-items: center;
            margin-bottom: 30px;
            padding: 0 15px;

            svg {
                height: 30px;
                width: 30px;
                margin-right: 10px;
                margin-top: 2px;
            }

            h4 {
                margin-bottom: 0px;
            }

            .btn-close {
                right: 30px;
                top: 25px;
            }
        }
    }

    &__mobile-filter-overlay {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        background: #fff;
        position: absolute;
        top: 0;
        z-index: 10;
        right: -100%;
        -webkit-transition: right 0.4s ease-in;
        transition: right 0.4s ease-in;

        .filter-item {
            display: flex;
            height: 67px;
            display: flex;
            justify-content: left;
            border-right: none;
            border-top: 1px solid #EFEFEF;
            align-items: center;
            padding: 0;
            width: 93%;
            margin: auto;

            svg {
                margin-left: auto;
                width: 25px;
                height: 25px;
            }

            &__dropdown.display {
                gap: unset;
                display: block;
                position: unset;
                width: 100%;
                box-shadow: none;
                padding: 10px 15px 15px 15px;
            }
        }

        .filter-bottom {
            background: #fff;
            position: fixed;
            bottom: 0;
            z-index: 100;
            box-shadow: 0 -5px 5px -5px rgba(0,0,0,0.1);
            width: 100%;
            padding: 20px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .method-overview-page__filter__reset-filter {
                font-size: 14px;
            }

            svg {
                width: 15px;
                height: 15px;
                margin-right: 12px;
            }

            .show-result-btn {
                display: block;
                width: auto;

                .button {
                    color: #fff;
                    margin: 0;
                    border-radius: 100px;
                    text-transform: uppercase;
                    -webkit-transition: all ease-in-out 350ms;
                    transition: all ease-in-out 350ms;
                    padding: 0.7em 1.5em;
                    font-size: 14px;
                }
            }

            .method-overview-page__filter__reset-filter {
                width: auto;
                margin: unset;
                border-top: none;
            }
        }

        &.open {
            width: 100%;
            right: 0;
            transition: right 0.4s ease-out;
            -webkit-transition: right 0.4s ease-out;
            overflow-y: auto;
            padding-bottom: 107px;

            .filter-item__dropdown.display {
                left: 0;
                top: 0;
                display: block;
                position: relative;
            }
        }
    }
}

.hide {
    display: none;
}

.show {
    display: block;
}

/*** List ***/
ul {
    list-style: none;
}