﻿.slick-list {
    overflow: visible;
}

.hero-section {

    &__media-wrapper {
        @include breakpoint(large down) {
            position: absolute;
            top: 0%;
            right: 0%;
            opacity: .3;
            padding: 20px 80px 20px;
            height: 100%;
            display: flex;
            align-items: center;
            width: 100% !important;
            justify-content: center;
        }
    }

    &__content-wrapper {
        display: flex;
        align-items: center;

        @include breakpoint(large down) {
            min-height: 700px;
            padding: 30px 30px;
            align-items: baseline;
            justify-content: center;
            max-width: 500px;
            margin: auto;
        }
    }

    &__content {
        padding: 0;

        @include breakpoint(xlarge) {
            padding: 0px 0px 0 50px;
        }

        @include breakpoint(xxlarge) {
            padding: 0px 0px 0 100px;
        }
    }

    &__body, &__cta {
        max-width: 100%;
        margin: 0 auto;

        @include breakpoint(xlarge) {
            padding-left: 55px;
        }
    }

    &__cta {

        .button {
            border-radius: 50px;
            text-transform: uppercase;
            padding: 20px 35px;
            font-size: 18px;

            &:hover {
            }
        }
    }

    &__body {
        font-size: 20px;

        p {
            font-weight: bold;

            @include breakpoint(xlarge) {
                font-weight: 100;
            }
        }
    }

    &__track {
        position: relative;
    }

    &__media {
        position: relative;
        overflow: hidden;

        .slick-initialized & {
            img {
                display: block;
                transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
                transform: scale(1.2);
                width: 100%;
            }
        }

        @include breakpoint(xlarge) {
            .slick-initialized & {
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background: $primary-color;
                    height: 100%;
                    z-index: 1;
                    transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
                    height: 100%;
                }
            }

            &.hero-section__media--has-link:after {
                content: "a";
                font-family: cocplayfulminds !important;
                color: $primary-color;
                width: 50px;
                height: 50px;
                position: absolute;
                bottom: 20px;
                right: 20px;
                /* transform: translateX(-50%) translateY(-50%); */
                z-index: 11;
                opacity: 1;
                background: $white;
                text-align: center;
                padding: 7px 0 0;
                font-size: 25px;
                border-radius: 100px;
                font-weight: bold;
                opacity: 0;
                transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
                transform-origin: 50% 50%;
                transform: scale(.9);
                transition-delay: .15s;
            }
        }

        .slick-active & {

            &:before {
                opacity: 0;
            }

            img {
                transform: scale(1);
                opacity: 1;
                transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
            }

            &:hover {
                &:before {
                    opacity: .35;
                    transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
                }

                &:after {
                    opacity: 1;
                    transform: scale(1);
                    transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
                }
            }
        }
    }

    &__headline {
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        font-size: 2.5rem;

        .slick-initialized & {
            opacity: 0;
            transform: translateY(50px);
        }

        @include breakpoint(xlarge) {
            font-size: 4rem;
        }

        .slick-active & {
            opacity: 1;
            transform: translateY(0px);
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
            transition-delay: .1s;
        }
    }

    &__body {
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        margin: 1.5rem 0 1.8rem;
        max-width: 500px;

        .slick-initialized & {
            opacity: 0;
            transform: translateY(50px);
        }

        .slick-active & {
            opacity: 1;
            transform: translateY(0px);
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
            transition-delay: .2s;
        }
    }

    &__cta {
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        transform-origin: 50%;

        .slick-initialized & {
            opacity: 0;
            transform: translateY(50px);
        }

        .slick-active & {
            transform: translateY(0px);
            opacity: 1;
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
            transition-delay: .3s;

            .button {
                opacity: 1;
                box-shadow: 0px 11px 11px rgba(0, 0, 0, 0);
                transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
                position: relative;

                &:after {
                    content: "a";
                    font-family: cocplayfulminds !important;
                    color: inherit;
                    position: absolute;
                    top: 50%;
                    right: 6px;
                    z-index: 11;
                    text-align: center;
                    padding: 12px 0 0;
                    font-size: 25px;
                    border-radius: 100px;
                    font-weight: bold;
                    opacity: 0;
                    transform: translateY(-50%) translateX(-20px);
                    text-transform: lowercase;
                    transition: all .35s cubic-bezier(0.7, 0, 0.3, 1);
                }

                &:hover {
                    transition: all .35s cubic-bezier(0.7, 0, 0.3, 1);
                    transition-delay: .0s;
                    box-shadow: 0px 11px 11px rgba(0, 0, 0, 0.11);
                    padding-right: 65px;

                    &:after {
                        opacity: 1;
                        transition: all .5s cubic-bezier(0.7, 0, 0.3, 1);
                    }
                }
            }
        }
    }

    .slick-dots {
        position: absolute;
        top: 50%;
        left: -16px;
        width: 16px;
        margin: 0;
        transform: translateY(-50%);

        @include breakpoint(xlarge) {
            left: -36px;
        }

        li {
            width: 16px;
            height: 16px;
            background: $light-gray;
            border-radius: 50%;
            cursor: pointer;
            list-style: none;
            margin: 8px 0;
            transition: all ease-in-out 200ms;
            cursor: pointer;
            transform: scale(1);
            transition: all .5s cubic-bezier(0.19, 1, 0.22, 1);
            transform-origin: 50% 50%;

            &.slick-active {
                background: get-color(primary);

                &:hover {
                    background: get-color(primary);
                    transform: scale(1);
                }
            }

            &:hover {
                background: darken(get-color(primary), 5%);
                transform: scale(1.1);
                transition: all .5s cubic-bezier(0.19, 1, 0.22, 1);
            }

            &:active {
                background: darken(get-color(primary), 10%);
                transform: scale(1.15);
            }

            button {
                font-size: 1;
                opacity: 0;
            }
        }
    }

    .grid-container {
        position: relative;

        .circle {
            content: "";
            position: absolute;
            top: -478px - 168px; // -design offset - header height
            left: 203px;
            width: 1377px;
            height: 1377px;
            border-radius: 50%;
            background: transparent;
            z-index: -1;
            display: none;

            @include breakpoint(xlarge down) {
                top: -110px;
            }

            @include breakpoint(large down) {
                top: 0px;
                left: 0;
                width: 1377px;
                height: 1377px;
                left: 50%;
                right: initial;
                transform: translateX(-50%) !important;
                top: -20px;
            }
        }
    }

    .frontpage & {
        .grid-container {
            .circle {
                background: get-color(primary);
                display: block;
            }
        }
    }
}

